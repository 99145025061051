<template>
  <div>
    <section v-if="featured && featured.length" class="hero is-large pb-4">
      <h3>
        Featured
      </h3>
      <ArticleCard :item="featured[0]" featured />
    </section>
    <div>
      <section>
        <h3>Recent</h3>
        <ArticleList :where="where" />
      </section>
    </div>
  </div>
</template>

<script>
import { POST_LIST } from "@/models/resources/operations.gql";
import {
  wherePostHasRole,
  whereIsFeatured
} from "@/models/resources/predicates.js";
import ArticleList from "@/components/resources/ArticleList.vue";
import ArticleCard from "@/components/resources/ArticleCard.vue";

export default {
  name: "AdminTab",
  components: {
    ArticleList,
    ArticleCard
  },
  data() {
    return {
      where: wherePostHasRole("Admin")
    };
  },
  apollo: {
    featured: {
      client: "cms",
      query: POST_LIST,
      variables() {
        const stage =
          !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts)
            ? "DRAFT"
            : "PUBLISHED";
        return {
          first: this.limit,
          stage,
          where: {
            ...wherePostHasRole("Admin"),
            ...whereIsFeatured(true)
          }
        };
      },
      update({ posts }) {
        return posts;
      }
    }
  }
};
</script>
